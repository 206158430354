import {acceptHMRUpdate, defineStore, storeToRefs} from 'pinia';
import {computed, ref} from 'vue';
import type {ProblemDetail} from '~/lib/types/fetchErrors';
import type {UserCommand, UserDecorator} from '~/lib/types/userTypes';
import useApi from '~/composables/useApi';
import {useCompanyContextStore} from '~/store/company/companyContext';

export const useUserDetailsStore = defineStore('userDetails', () => {
	const {PUT, GET, POST} = useApi();
	const companyContextStore = useCompanyContextStore();

	const loading = ref<boolean>(true);
	const problemDetail = ref<ProblemDetail>();

	const userDetails = ref<UserDecorator>();

	const clearUserDetails = () => {
		userDetails.value = undefined;
		loading.value = false;
		problemDetail.value = undefined;
	};

	const fetchUserDetails = async () => {
		loading.value = true;
		problemDetail.value = undefined;

		const {data, error} = await GET(`/user`);

		if (error || !data) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		userDetails.value = data;
		loading.value = false;
		return true;
	};

	const updateUserDetails = async (body: UserCommand) => {
		loading.value = true;
		problemDetail.value = undefined;

		const {data, error} = await PUT(`/user`, {
			body
		});

		if (error || !data) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		userDetails.value = data;
		loading.value = false;
		return true;
	};

	const resetPasswordOfCurrentUser = async () => {
		loading.value = true;
		problemDetail.value = undefined;

		const {error} = await GET(`/user/reset-password`);

		if (error) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		loading.value = false;
		return true;
	};

	const updateEmailOfCurrentUser = async () => {
		loading.value = true;
		problemDetail.value = undefined;

		const {error} = await POST(`/user/update-email`);

		if (error) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		loading.value = false;
		return true;
	};

	const isAllowedToManageCompanies = computed(() => {
		return userDetails.value?.applicationRoles?.includes('APPLICATION_ADMIN') ?? false;
	});

	const {writeAccessForCurrentCompany, writeAccessForCurrentRie} = storeToRefs(companyContextStore);
	const isAllowedToWriteToCompany = computed(() => writeAccessForCurrentCompany.value);
	const isAllowedToWriteToRie = computed(() => writeAccessForCurrentRie.value);

	return {
		clearUserDetails,
		fetchUserDetails,
		updateUserDetails,
		resetPasswordOfCurrentUser,
		updateEmailOfCurrentUser,
		isAllowedToManageCompanies,
		isAllowedToWriteToCompany,
		isAllowedToWriteToRie,
		problemDetail,
		userDetails,
		loading
	};

});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserDetailsStore, import.meta.hot));
}
